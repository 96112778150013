import Head from 'next/head';
import Link from 'next/link';
import {I18nText} from '../i18n/I18nText';
import {useI18NStringProvider} from '../i18n/i18n';

export default function () {
    const i18n = useI18NStringProvider();

    return <div className="p-3">
        <Head>
            <title>{`${i18n('404.html_title')} - CornHub`}</title>
        </Head>
        <img src="https://cornhub.objectdepot.net/static/cornmaze.gif" alt="Corn Maze" className="m-auto"/>
        <h1 className="text-center text-2xl font-bold"><I18nText id="404.title"/></h1>
        <h2 className="text-center text-lg">
            <I18nText id="404.description"/>
            <br/>
            <Link href="/" className="text-primary underline"><I18nText id="404.action.return"/></Link>
        </h2>
    </div>;
}
